define("ember-svg-jar/inlined/text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M280-160v-520H80v-120h520v120H400v520H280zm360 0v-320H520v-120h360v120H760v320H640z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "24",
      "viewBox": "0 -960 960 960",
      "width": "24"
    }
  };
});